<template>
    <div>
        <div v-for="(number, index) in resultNumbers"
             :key="index"

             :style="getNumberPosition(index)"
             class="number__bg"
        >
            <img :src="numberBackgroundImage()"/>

            <img v-if="isNumberOrderlyMatch(index)"
                 :src="orderlyNumberImage(number)"

                 class="result__number"
            />

            <img v-else-if="isNumberDisorderlyMatch(index) || isNumberMatch(index)"
                 :src="disorderlyNumberImage(number)"

                 class="result__number"
            />

            <img v-else
                 :src="numberImage(number)"
                 class="result__number"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resultNumbers: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        disorderlyMatch: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        orderlyMatch: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        matchNumbers: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            number: {
                width: 100
            },
            margin: {
                left: 30
            }
        }
    },
    methods: {
        numberImage(number) {
            return require(`@/assets/games/keno/pick-keno/white/${number}.png`);
        },
        disorderlyNumberImage(number) {
            return require(`@/assets/games/keno/pick-keno/orange/${number}.png`);
        },
        orderlyNumberImage(number) {
            return require(`@/assets/games/keno/pick-keno/red/${number}.png`);
        },
        numberBackgroundImage() {
            return require(`@/assets/games/keno/pick-keno/result_bg.png`);
        },
        getNumberPosition(index) {
            let left = (this.number.width + this.margin.left) * index;

            return {
                left: `${left}px`,
                top: '0px'
            }
        },
        isNumberDisorderlyMatch(index) {
            return this.disorderlyMatch.includes(index);
        },
        isNumberOrderlyMatch(index) {
            return this.orderlyMatch.includes(index);
        },
        isNumberMatch(index) {
            return this.matchNumbers.includes(index);
        },
    }
}
</script>

<style scoped>
.result__number {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.number__bg {
    position: absolute;
}
</style>
