<template>
    <div class="background">
        <tickets :result-numbers="stateData.roundResult.resultNumbers"
                 :tickets="stateData.roundResult.tickets"

                 class="tickets__wrapper"
        />

        <div>
            <result :disorderly-match="disorderlyMatch"
                    :match-numbers="matchNumbers"
                    :orderly-match="orderlyMatch"
                    :result-numbers="stateData.roundResult.resultNumbers"

                    class="result__wrapper"
            />
        </div>
    </div>
</template>

<script>
import PickKenoTickets from "./PickKenoTickets";
import PickKenoResult from "./PickKenoResult";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        tickets: [{
                            bet: null,
                            place: null,
                            numbers: null,
                            totalWin: null,
                            orderCombination: {
                                matchCount: null,
                                matchNumbers: null,
                                win: null
                            },
                            disorderCombination: {
                                matchCount: null,
                                matchNumbers: null,
                                win: null
                            },
                            matchNumbers: null
                        }],
                        resultNumbers: null,
                        numbers: null,
                        covers: null,
                        isFreeSpin: null,
                    },
                    denom: null
                }
            }
        }
    },
    computed: {
        backgroundImage() {
            return require('@/assets/games/keno/pick-keno/bg.png');
        },
        orderlyMatch() {
            let matched = new Set();

            let tickets = this.stateData.roundResult.tickets;

            for (let ticketKey in tickets) {
                let matchNumbers = tickets[ticketKey].orderCombination.matchNumbers;

                for (let number in matchNumbers)
                    matched.add(matchNumbers[number]);
            }

            return Array.from(matched);
        },
        disorderlyMatch() {
            let matched = new Set();

            let tickets = this.stateData.roundResult.tickets;

            for (let ticketKey in tickets) {
                let matchNumbers = tickets[ticketKey].disorderCombination.matchNumbers;

                for (let number in matchNumbers)
                    matched.add(matchNumbers[number]);
            }

            return Array.from(matched);
        },
        matchNumbers() {
            let matched = new Set();

            let tickets = this.stateData.roundResult.tickets;

            for (let ticketKey in tickets) {
                let matchNumbers = tickets[ticketKey].matchNumbers;

                for (let number in matchNumbers)
                    matched.add(matchNumbers[number]);
            }

            return Array.from(matched);
        }
    },
    components: {
        'tickets': PickKenoTickets,
        'result': PickKenoResult
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 679);
        this.$store.dispatch('workspace/setWidth', 1058);
    }
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/keno/pick-keno/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1058px;
    height: 679px;
}

.result__wrapper {
    position: relative;
    top: 30px;
    left: 150px;
}

.tickets__wrapper {
    position: relative;
    top: 250px;
    left: 150px;
}
</style>
